import React from 'react';
import AddFilmForm from './AddFilmForm ';

const AddFilmPage = () => {
  return (
    <div>
      <AddFilmForm />
    </div>
  );
};

export default AddFilmPage;
